import { fetchWrapper } from "@gogig-in/gogig-clients";
import { SpotrueApiBaseUrl } from "./baseUrls";

export default function getAuditFunction(authHeader: string) {
  return fetchWrapper(`${SpotrueApiBaseUrl}/getAuditRequest`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader,
    },
  });
}
