import { Box, Container } from "@mui/material";
import SpoTrueLoginComponent from "../components/login/SpoTrueLoginComponent";
import { useAuthContext } from "../providers/AuthProvider";
import { useNavigate } from "react-router-dom";

const SpoTrueLoginPage = () => {
  const {isAuthenticated}=useAuthContext();
  const navigate =useNavigate()
  if (isAuthenticated) {
    navigate("/SpoTrue"); 
  }
  return (
    <Container sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          maxWidth: "400px",
          border: "1px solid #ddd",
          borderRadius: "10px",
          padding: "25px",
          marginTop: "25px",
        }}
      >
        <SpoTrueLoginComponent />
      </Box>
    </Container>
  );
};

export default SpoTrueLoginPage;
