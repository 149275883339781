import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";

// Fix for Tooltip issue, make sure you're passing the correct component and props

const SpoTrueLogo = () => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/SpoTrue");
  };

  return (
    <Box onClick={onClick}>
      <img
        src={`${process.env.PUBLIC_URL}/images/logos/spotrue.png`}
        alt=" SpoTrue "
        style={{ width: 200}}
      />
    </Box>
  );
};

export default SpoTrueLogo;
